<template>
  <!-- <div > -->

  <div
    class="row position-relative"
    :class="
      RoutePage == 'QuizFinal' ? 'm-0 p-0 w-100 big-div-quiz' : ' w-85 big-div'
    "
    style="z-index: 100"
    :style="getPageDesign()"
  >
    <!-- BackGroundDiv start-->
    <div
      class="bg lazyload"
      :data-bg="MainImgSrc(getImageSrc(pageDesign.imageBlock, isDesktopView))"
      v-if="getImageAlign == 'no'"
      :key="getImageSrc(pageDesign.imageBlock, isDesktopView)"
      :style="
        getbackgroundImage(
          IsImageAdded,
          pageDesign.imageBlock,
          getImageAlign,
          isDesktopView
        ) + getBgDivStyles()
      "
    ></div>
    <!-- BackGroundDiv end-->
    <div
      class="px-0 px-0"
      v-show="showLeftImage"
      :class="[
        {
          'col-12 col-lg-12 col-xs-12': !isDesktopView,
          'image-division col-6': isDesktopView,
        },
      ]"
      :style="
        calculateImageWidth(
          pageDesign.imageBlock,
          isDesktopView,
          IsImageVariableWidthActive
        )
      "
    >
      <div :class="isDesktopView ? 'image-division' : ''">
        <div :style="height100(isDesktopView)">
          <LazyImage
            :key="getImageSrc(pageDesign.imageBlock, isDesktopView)"
            :style="
              getUniversalImageStyle(
                isDesktopView,
                pageDesign.imageBlock.style,
                IsImageVariableWidthActive,
                pageDesign.imageBlock
              ) +
              getImageRotation(pageDesign.imageBlock.style) +
              getImageRotation(pageDesign.imageBlock.style)
            "
            :class="[
              `pic-${getSelectedPage.order}`,
              getImageSrc(pageDesign.imageBlock, isDesktopView) ==
              '/images/default_start_page.png'
                ? 'default-bg'
                : '',
            ]"
            :src="MainImgSrc(getImageSrc(pageDesign.imageBlock, isDesktopView))"
            alt
          />
        </div>
      </div>
    </div>

    <div
      :class="[!IsImageAdded ? 'col-12' : imageAddedLayout]"
      class=" "
      :style="
        getMainPaddingCss(pageDesign.pageStyles, isDesktopView) +
        getScrollCSS(
          pageDesign.pageStyles,
          isDesktopView,
          pageDesign.imageBlock.style
        ) +
        calculateContentWidth(
          pageDesign.imageBlock,
          isDesktopView,
          IsImageVariableWidthActive,
          pageDesign.pageStyles
        )
      "
    >
      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          height: 100%;
        "
      >
        <div style="overflow-y: scroll" class="h-100">
          <div
            v-for="(pro, ind) in pageDesign.blocksArray"
            :key="'preview' + ind"
            :class="`item-${ind}`"
            :data-aos="animationType"
            :data-aos-duration="getAnimationSpeedinMillis"
            :data-aos-delay="AOSAnimationDelay(ind)"
            :data-aos-anchor="`.item-${ind == 0 ? ind : ind - 1}`"
          >
            <div
              id="preview"
              :style="getHeightWidth(pro.style)"
              class="content"
              style="position: relative"
            >
              <div
                v-if="pro.type == 'text'"
                :style="
                  getColorCss(pro.style) +
                  getPaddingCss(pro.style) +
                  getMarginCss(pro.style, isDesktopView) +
                  getColorWithOpacity(pro.style) +
                  getTextRotate(pro.style) +
                  getLineHeight(pro.style) +
                  getTextAlign(pro.style) +
                  getFontStyle(pro.style, isDesktopView)
                "
                v-html="pro.content"
              ></div>

              <div
                v-if="pro.type == 'paymentButton'"
                :style="`display:flex;justify-content: ${pro.style.position} ;  `"
              >
                <button
                  v-if="currentPaymentMethod == 'Stripe'"
                  :style="getButtonCss(pro.style, isDesktopView)"
                  @click="PayStripe"
                >
                  <span
                    :style="{
                      opacity: `${pro.style.textOpacity}%`,
                      fontSize: `${pro.style.fontSize}px`,
                    }"
                  >
                    {{ pro.text }}
                  </span>
                  {{ pro.style.alignItems }}
                </button>
                <div
                  id="PaypalButton"
                  v-if="currentPaymentMethod == 'Paypal'"
                  style="width: 28%"
                ></div>
              </div>
              <div v-if="pro.type == 'price'">
                <div>
                  <p class="m-0" :style="getPriceCss(pro.style)">
                    <span>{{ pro.selectedCurrency.currencySymbol }}</span
                    >{{ pro.amountValue }}
                  </p>
                </div>
              </div>

              <div v-if="pro.type == 'imageComp'">
                <InlineImageComp :pro="pro" :isDesktopView="isDesktopView" />
              </div>

              <div
                v-if="pro.type == 'tooltip'"
                :style="`display:flex;justify-content: ${pro.style.position} ;  `"
              >
                <button
                  :style="
                    getButtonCss(pro.style, isDesktopView) +
                    getCircularBtn(pro.isBtnRounded)
                  "
                  @mouseenter="pro.isTooltipVisible = true"
                  @mouseleave="pro.isTooltipVisible = false"
                >
                  <span v-if="pro.useIcon">
                    <BIcon :icon="pro.selectedIcon" />
                  </span>

                  <span
                    v-else
                    :style="{
                      opacity: `${pro.style.textOpacity}%`,
                      fontSize: `${pro.style.fontSize}px`,
                    }"
                  >
                    {{ pro.text }}
                  </span>
                </button>
                <ToolTipComp
                  v-if="pro.isTooltipVisible"
                  :tooltipData="pro"
                  :isDesktopView="isDesktopView"
                />
              </div>
              <div
                v-if="pro.type == 'html'"
                :style="
                  getMarginCss(pro.style, isDesktopView) +
                  getColorWithOpacity(pro.style) +
                  getTextRotate(pro.style) +
                  getTextAlign(pro.style)
                "
              >
                <HtmlElement :pro="pro" :isDesktopView="isDesktopView" />
              </div>
            </div>
          </div>
        </div>

        <!-- Stripe Back Button -->
        <div>
          <div
            :style="getHeightWidth(StaticButtonElement.style)"
            class="content"
          >
            <div class="w-100">
              <div
                class="d-flex"
                :style="` justify-content: ${StaticButtonElement.style.buttonPosition}`"
              >
                <button
                  style="z-index: 1000"
                  :style="
                    getCommonButtonCSS(
                      StaticButtonElement.style,
                      isDesktopView
                    ) +
                    getBackNextButtonCss(
                      StaticButtonElement.content.backButton.style,
                      isDesktopView
                    )
                  "
                  @click="backPage"
                  class="ButtonHover"
                >
                  <span
                    :style="{
                      opacity: `${StaticButtonElement.content.backButton.style.textOpacity}%`,
                      fontSize: `${StaticButtonElement.content.backButton.style.fontSize}px`,
                    }"
                    >{{ StaticButtonElement.content.backButton.text }}</span
                  >
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="px-0 px-0"
      v-show="showRightImage"
      :class="[
        {
          'col-12 col-lg-12 col-xs-12': !isDesktopView,
          'image-division col-6': isDesktopView,
        },
      ]"
      :style="
        calculateImageWidth(
          pageDesign.imageBlock,
          isDesktopView,
          IsImageVariableWidthActive
        )
      "
    >
      <div :class="isDesktopView ? 'image-division' : ''">
        <div :style="height100(isDesktopView)">
          <LazyImage
            :key="getImageSrc(pageDesign.imageBlock, isDesktopView)"
            :style="
              getUniversalImageStyle(
                isDesktopView,
                pageDesign.imageBlock.style,
                IsImageVariableWidthActive,
                pageDesign.imageBlock
              ) +
              getImageOpacity(pageDesign.imageBlock.style) +
              getImageRotation(pageDesign.imageBlock.style)
            "
            :class="[
              `pic-${getSelectedPage.order}`,
              getImageSrc(pageDesign.imageBlock, isDesktopView) ==
              '/images/default_start_page.png'
                ? 'default-bg'
                : '',
            ]"
            :src="MainImgSrc(getImageSrc(pageDesign.imageBlock, isDesktopView))"
            alt
          />
        </div>
      </div>
    </div>

    <!-- Show Logo Footer -->
    <div
      class="position-absolute"
      style="bottom: 0; right: 0; z-index: 1111"
      v-if="getPreviewSettings && getPreviewSettings.showLogoStatus"
    >
      <div class="text-end pr-3 pb-3">
        <a href="https://www.quizell.com" target="_blank" style="">
          <img
            src="https://cdn.shopify.com/s/files/1/0620/9998/0532/files/Group_69111_2.svg?v=1660804067"
            alt="Quizell"
          />
        </a>
      </div>
    </div>
  </div>

  <!-- </div> -->
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions, mapMutations } from "vuex";
import cssFunctionMixin from "../customize/mixin/cssFunction.js";
export default {
  mixins: [cssFunctionMixin],
  props: [
    "isDesktopView",
    "apiToken",
    "isTest",
    "animationType",
    "RoutePage",
    "getIsAnimationEnabled",
    "getAnimationSpeedinMillis",
  ],
  components: {
    HtmlElement: () =>
      import(/* webpackChunkName: "HtmlElement" */ "./HtmlElement.vue"),
    ToolTipComp: () =>
      import(/* webpackChunkName: "ToolTipComp" */ "./ToolTipComp.vue"),
    InlineImageComp: () =>
      import(
        /* webpackChunkName: "InlineImageComp" */ "./components/InlineImageComp.vue"
      ),
  },
  data() {
    return {
      activeSideBar: "pageStyle",
      pageDesign: {},
      email: "",
      name: "",
      phone: "",
      date: "",
      website: "",
      organisation: "",
      address_1: "",
      address_2: "",
      city: "",
      zip_code: "",
      state: "",
      country: "",
      delayIncrement: 0.2, // The delay between each item
      isPaypalBtnLoading: false,
      paypal_sdk_url: `https://www.paypal.com/sdk/js`,
      paypalIntent: `capture`,
    };
  },
  computed: {
    StaticButtonElement() {
      const index = this.pageDesign.blocksArray.findIndex(
        (x) => x.type == "button"
      );
      return this.pageDesign.blocksArray[index];
    },
    getImageAlign() {
      if (
        this.pageDesign.pageStyles.useMobileImageAlign &&
        !this.isDesktopView
      ) {
        return this.pageDesign.pageStyles.mobileImageAlign;
      } else {
        return this.pageDesign.pageStyles.imageAlign;
      }
    },

    showLeftImage() {
      return (
        (this.isDesktopView &&
          this.pageDesign.imageBlock.style.displayDesktop &&
          this.getImageAlign == "left") ||
        (!this.isDesktopView &&
          this.pageDesign.imageBlock.style.displayMobile &&
          (this.getImageAlign == "left" || this.getImageAlign == "right"))
      );
    },
    showRightImage() {
      return (
        this.isDesktopView &&
        this.pageDesign.imageBlock.style.displayDesktop &&
        this.getImageAlign == "right"
      );
    },
    firstTextIndex() {
      return this.pageDesign.blocksArray.findIndex((e) => e.type == "text");
    },
    firstButtonIndex() {
      return this.pageDesign.blocksArray.findIndex((e) => e.type == "button");
    },
    getPriceIndex() {
      return this.pageDesign.blocksArray.findIndex((e) => e.type == "price");
    },
    getPricePaymentButtonIndex() {
      return this.pageDesign.blocksArray.findIndex(
        (e) => e.type == "paymentButton"
      );
    },
    getPaymentLinkValue() {
      let index = this.getPriceIndex;
      if (index > -1) {
        return this.pageDesign.blocksArray[index].stripePaymentLink;
      } else {
        return null;
      }
    },
    ...mapGetters([
      "getSelectedPage",
      "getUploadedImage",
      "getTotalPages",
      "getPreviewSettings",
      "getQuizStarted",
      "getAnimationSetting",
      "getSavedLeadID",
    ]),
    CurrentSelectedPage() {
      return this.getSelectedPage;
    },
    IsImageAdded() {
      if (
        this.getImageSrc(this.pageDesign.imageBlock, this.isDesktopView) !== ""
      )
        return true;
      return false;
    },
    IsImageVariableWidthActive() {
      return this.IsImageAdded && this.getImageAlign != "no" ? true : false;
    },
    imageAddedLayout() {
      if (this.getImageAlign == "no") return "col-12";
      if (!this.isDesktopView) return "col-12";
      // return "col-12 col-xs-12 col-md-6 col-sm-12 px-4 col-12 col-xs-12";
      return "col-12 col-xs-12 col-sm-6 col-md-6 ";
    },
    getStripePriceObjIndex() {
      return this.pageDesign.blocksArray.findIndex((e) => e.type == "price");
    },
    currentPaymentMethod() {
      return this.pageDesign.blocksArray[this.getPriceIndex]
        .selectedPaymentMethod;
    },
    paymentBlockData() {
      return this.pageDesign.blocksArray[this.getPriceIndex];
    },
    getPaypalClientID() {
      return this.pageDesign.blocksArray[this.getPriceIndex].paypalClientID;
    },
    PaypalButtonStyles() {
      return this.pageDesign.blocksArray[this.getPricePaymentButtonIndex]
        .paypalButtonData;
    },
    isRecurringPayment() {
      return this.pageDesign.blocksArray[this.getPriceIndex].isRecuringPayment;
    },
  },

  created() {
    this.pageDesign = this.getStartedPageValues();
    let priceIndex = this.getStripePriceObjIndex;
    if (priceIndex) {
      if (
        this.pageDesign.blocksArray[priceIndex].selectedCurrency.currencyCode ==
          "GBP" &&
        this.pageDesign.blocksArray[priceIndex].selectedCurrency
          .currencySymbol == "â‚¬"
      ) {
        this.pageDesign.blocksArray[
          priceIndex
        ].selectedCurrency.currencySymbol = "Â£";
      }

      if (
        this.pageDesign.blocksArray[priceIndex].Currencies[1].currencyCode ==
          "GBP" &&
        this.pageDesign.blocksArray[priceIndex].Currencies[1].currencySymbol ==
          "â‚¬"
      ) {
        this.pageDesign.blocksArray[priceIndex].Currencies[1].currencySymbol =
          "Â£";
      }
    }
  },
  methods: {
    AOSAnimationDelay(index) {
      const baseDelay = parseInt(this.getAnimationSpeedinMillis) || 200;
      return this.getIsAnimationEnabled ? (index + 1) * baseDelay : 50;
    },
    UpdateDynamicText() {
      if (this.getSavedLeadID.full_name !== "") {
        this.name = this.getSavedLeadID.full_name;
      }
      if (this.getSavedLeadID.email !== "") {
        this.email = this.getSavedLeadID.email;
      }
      if (this.getSavedLeadID.phone_number !== "") {
        this.phone = this.getSavedLeadID.phone_number;
      }
      if (this.getSavedLeadID.date !== "") {
        this.date = this.getSavedLeadID.date;
      }
      if (this.getSavedLeadID.website !== "") {
        this.website = this.getSavedLeadID.website;
      }
      if (this.getSavedLeadID.organisation !== "") {
        this.organisation = this.getSavedLeadID.organisation;
      }
      if (
        Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "address1") &&
        this.getSavedLeadID.address1 !== ""
      ) {
        this.address_1 = this.getSavedLeadID.address1;
      }
      if (
        Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "address2") &&
        this.getSavedLeadID.address2 !== ""
      ) {
        this.address_2 = this.getSavedLeadID.address2;
      }
      if (
        Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "city") &&
        this.getSavedLeadID.city !== ""
      ) {
        this.city = this.getSavedLeadID.city;
      }
      if (
        Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "country") &&
        this.getSavedLeadID.country !== ""
      ) {
        this.country = this.getSavedLeadID.country;
      }
      if (
        Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "state") &&
        this.getSavedLeadID.state !== ""
      ) {
        this.state = this.getSavedLeadID.state;
      }
      if (
        Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "zip_code") &&
        this.getSavedLeadID.zip_code !== ""
      ) {
        this.zip_code = this.getSavedLeadID.zip_code;
      }
    },
    backPage() {
      this.backPageAnimation();
      this.goToBackPreviewPage();
    },
    handleMessage(event) {
      if (event.data == "paymnet_success") {
        console.log("Stripe Success Event", event);
        this.goToNextPreviewPage(this.getSelectedPage.value);
      } else {
        if (typeof event.data !== "string") return;
        this.$toasted.show(`Payment Error`, {
          theme: "toasted-primary",
          type: "error",

          position: "bottom-center",
          duration: 2000,
        });
      }
    },
    ...mapMutations([
      "setQuizStarted",
      "nextPageAnimation",
      "backPageAnimation",
    ]),
    ...mapActions(["goToNextPreviewPage", "goToBackPreviewPage"]),
    PayStripe() {
      if (this.getPaymentLinkValue) {
        let w = 850;
        let h = 800;
        var left = screen.width / 2 - w / 2;
        var top = screen.height / 2 - h / 2;
        window.open(
          this.getPaymentLinkValue,
          "Stripe Payment",
          "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
            w +
            ", height=" +
            h +
            ", top=" +
            top +
            ", left=" +
            left
        );
      }
    },
    getPageDesign() {
      let design =
        "text-align:" +
        this.pageDesign.pageStyles.textAlign +
        ";color:" +
        this.pageDesign.pageStyles.color +
        "; background:" +
        this.pageDesign.pageStyles.backgroundColor +
        "; text-align:" +
        this.pageDesign.pageStyles.textAlign +
        "; color:" +
        this.pageDesign.pageStyles.color +
        "; text-align:" +
        this.pageDesign.pageStyles.textAlign +
        "; width:" +
        this.pageDesign.pageStyles.width +
        "px; " +
        this.getQuizPageHeight(
          this.pageDesign.pageStyles.height,
          this.RoutePage,
          this.isTest
        );

      return design;
    },
    getStartedPageValues() {
      let getStartedDesignValues = {};
      if (this.getTotalPages && this.getSelectedPage) {
        getStartedDesignValues = this.getSelectedPage.pageDesign;
      }
      return getStartedDesignValues;
    },

    covertQuestionTitle() {
      this.pageDesign.blocksArray.forEach((x) => {
        if (x.type == "text") {
          x.questionTitle = true;
        }
      });
    },
    // Paypal Functionality
    loadPayPalSDK() {
      this.isPaypalBtnLoading = true;
      const paypal_sdk_url = "https://www.paypal.com/sdk/js";
      const client_id = this.getPaypalClientID;
      let currency = this.paymentBlockData.selectedCurrency.currencyCode;
      let url = "";
      if (this.isRecurringPayment) {
        url = `${paypal_sdk_url}?client-id=${client_id}&enable-funding=venmo&currency=${currency}&intent=subscription&vault=true`;
      } else {
        url = `${paypal_sdk_url}?client-id=${client_id}&enable-funding=venmo&currency=${currency}&intent=capture`;
      }

      this.url_to_head(url)
        .then(() => {
          this.isPaypalBtnLoading = false;

          if (this.isRecurringPayment) {
            this.initPayPalSubscriptionButton();
          } else {
            this.initPayPalButton();
          }
        })
        .catch((error) => {
          this.isPaypalBtnLoading = false;
          console.error(error);
        });
    },
    url_to_head(url) {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = url;
        script.onload = resolve;
        script.onerror = () => reject(new Error("Error loading script."));
        document.head.appendChild(script);
      });
    },
    initPayPalButton() {
      if (!window.paypal) {
        console.error("PayPal SDK not loaded.");
        return;
      }

      window.paypal
        .Buttons({
          style: {
            shape: this.PaypalButtonStyles.shape,
            color: this.PaypalButtonStyles.color,
            layout: this.PaypalButtonStyles.layout,
            label: this.PaypalButtonStyles.label,
          },

          createOrder: async () => {
            if (!this.isRecurringPayment) {
              let data = {
                quizId: this.CurrentSelectedPage.quiz_id,
              };
              const response = await axios.post(`/paypal/create-order`, data);

              if (response.status == 200 && response.data.data.id) {
                return response.data.data.id;
              } else {
                this.$toasted.show("Error occured while creating order", {
                  theme: "toasted-primary",
                  position: "bottom-center",
                  duration: 2000,
                });
              }
            }
          },
          onApprove: () => {
            this.goToNextPreviewPage(this.getSelectedPage.value);
          },
          onCancel: () => {
            this.$toasted.show("Your payment process has been interrupted", {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
              type: "error",
            });
          },
          onError: (err) => {
            this.$toasted.show(err, {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
              type: "error",
            });
          },
        })
        .render(`#PaypalButton`);
    },
    initPayPalSubscriptionButton() {
      if (!window.paypal) {
        console.error("PayPal SDK not loaded.");
        return;
      }

      const vm = this;

      window.paypal
        .Buttons({
          style: {
            shape: vm.PaypalButtonStyles.shape,
            color: vm.PaypalButtonStyles.color,
            layout: vm.PaypalButtonStyles.layout,
            label: vm.PaypalButtonStyles.label,
          },
          createSubscription: function (data, actions) {
            if (vm.isRecurringPayment) {
              return actions.subscription.create({
                plan_id: vm.paymentBlockData.paypalPaymentDetail.paypal_plan_id,
              });
            }
          },

          onApprove: () => {
            vm.goToNextPreviewPage(vm.getSelectedPage.value);
          },
          onCancel: () => {
            vm.$toasted.show("Your payment process has been interrupted", {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
              type: "error",
            });
          },
          onError: (err) => {
            vm.$toasted.show(err, {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
              type: "error",
            });
          },
        })
        .render("#PaypalButton");
    },
  },
  mounted() {
    if (this.currentPaymentMethod === "Paypal") {
      this.loadPayPalSDK();
    } else {
      window.addEventListener("message", this.handleMessage);
    }
    this.UpdateDynamicText();
  },
  beforeDestroy() {
    if (this.currentPaymentMethod === "Stripe") {
      window.removeEventListener("message", this.handleMessage);
    }

    const paypalScript = document.querySelector(
      'script[src^="https://www.paypal.com/sdk/js"]'
    );
    if (paypalScript) {
      paypalScript.remove();
    }
  },
};
</script>

<style>
.arrow1::before {
  content: "\2023";
  font-size: 58px;
  position: absolute;
  top: -47px;
  right: 0px;
  transform: rotate(26deg);
  color: #ffffff;
}

.slideRTL {
  animation-name: slide-in;
  animation-duration: var(--animation-speed);
  animation-timing-function: forwards;
  animation-fill-mode: both;
}

.slideLTR {
  animation-name: slide-out;
  animation-duration: var(--animation-speed);
  animation-timing-function: forwards;
  animation-fill-mode: both;
}
</style>
